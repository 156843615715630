import React, { Component } from "react";
// Components
import Helmet from "react-helmet";

// graphql
import { graphql } from "gatsby";
import Hero from "slices/Hero";
import BigCallout from "slices/BigCallout";
import LinkGrid from "components/LinkGrid";
import ClientsGrid from "components/ClientsGrid";
import Dropdown from "components/Dropdown";
// import Emitter from 'utils/Emitter';
// import events from 'utils/events';
import getCountryCode from "utils/getCountryCode";
// import getLocation from 'utils/getLocation';
import getPageMeta from "utils/getPageMeta";

class WorkPage extends Component {
  constructor(props) {
    super(props);
    this.cases = this.props.data.page.data.body
      .filter(item => item.slice_type === "case_studies")[0]
      .items.map(item =>
        item.case_study && item.case_study.document
          ? item.case_study.document[0]
          : null
      )
      .filter(Boolean);
    // industry uids
    this.industries = this.props.data.clients.edges
      .map(edge => {
        return edge.node.tags.map(tag => ({
          name: `${tag.charAt(0).toUpperCase()}${tag.substr(1)}`,
          value: tag.toLowerCase().trim()
        }));
      })
      .reduce((acc, val) => {
        if (!val) {
          return acc;
        }
        for (let i = 0; i < acc.length; i++) {
          for (let j = 0; j < val.length; j++) {
            if (acc[i].value === val[j].value) {
              return acc;
            }
          }
        }
        return acc.concat(val);
      });

    this.industries.sort((a, b) => {
      if (b.value > a.value) {
        return -1;
      }
      if (a.value > b.value) {
        return 1;
      }
      return 0;
    });

    // regions
    this.ddRegions = [
      { name: "Worldwide", value: null },
      { name: "U.S.", value: "us" },
      { name: "Canada", value: "canada" },
      { name: "Europe", value: "europe" },
      { name: "Asia", value: "asia" }
    ];

    // set available industries for dropdown (never changes)
    this.ddIndustries = Array.from(this.industries);
    this.ddIndustries.unshift({ name: "All industries", value: null });

    // Emitter.on(events.countryCodeSet, this.handleCountryCodeSet);

    // let countryCode = null;

    // if (typeof window !== 'undefined' && window.localStorage) {
    //   countryCode = localStorage.getItem('CitizenRelations__countryCode');
    //   if (countryCode) {
    //     countryCode = getCountryCode(countryCode);
    //   } else {
    //     getLocation();
    //   }
    // }

    let hero = this.props.data.page.data.body.filter(
      item => item.slice_type === "hero"
    )[0];

    hero.animationDelay = 500;
    hero.primary.theme = "red";
    hero.large = true;
    hero.paragraphOffset = true;

    this.state = {
      regionFilter: null,
      industryFilter: null,
      hero,
      callout: this.props.data.page.data.body.filter(
        item => item.slice_type === "callout"
      )[0],
      cases: this.getFilteredCases(null, null, null) //this.getFilteredCases(null, null, countryCode),
      //countryCode: countryCode
    };
  }

  handleCountryCodeSet = code => {
    let matchedCode = getCountryCode(code);
    this.setState({
      countryCode: matchedCode,
      cases: this.getFilteredCases(
        this.state.regionFilter,
        this.state.industryFilter,
        matchedCode
      )
    });
  };

  getFilteredCases = (regionFilter, industryFilter, countryCode) => {
    let result = this.cases
      .map(c => {
        let result;
        switch (true) {
          case regionFilter != null && industryFilter != null:
            result =
              c.data.client.document &&
              c.data.client.document[0].tags.includes(industryFilter) &&
              c.tags.includes(regionFilter)
                ? c
                : null;
            break;
          case regionFilter != null && !industryFilter:
            result = c.tags.includes(regionFilter) ? c : null;
            break;
          case industryFilter != null && !regionFilter:
            result =
              c.data.client.document &&
              c.data.client.document[0].tags.includes(industryFilter)
                ? c
                : null;
            break;
          default:
            result = c;
            break;
        }
        return result;
      })
      .filter(Boolean);

    if (countryCode) {
      result.sort((a, b) => {
        let aResult = a.tags.includes(countryCode) ? 1 : 0;
        let bResult = b.tags.includes(countryCode) ? 1 : 0;
        return bResult - aResult;
      });
    }

    return result;
  };

  onIndustryChange = ({ value }) => {
    if (this.state.industryFilter === value) {
      return;
    }
    this.setState({
      industryFilter: value,
      cases: this.getFilteredCases(
        this.state.regionFilter,
        value,
        this.state.countryCode
      )
    });
  };

  onRegionChange = ({ value }) => {
    if (this.state.regionFilter === value) {
      return;
    }
    this.setState({
      regionFilter: value,
      cases: this.getFilteredCases(
        value,
        this.state.industryFilter,
        this.state.countryCode
      )
    });
  };

  render() {
    // Set the document object
    const { page, clients, offices } = this.props.data;
    const { title, show_case_study_filters, show_client_filters } = page.data;
    const { hero, callout, cases } = this.state;
    const showCaseFilters = show_case_study_filters === "yes";
    const showClientFilters = show_client_filters === "yes";

    return (
      <React.Fragment>
        <Helmet
          title={`${title.text} | Citizen Relations`}
          meta={getPageMeta(
            this.props.pageContext.globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        <Hero {...hero}>
          {showCaseFilters && (
            <aside style={{ display: `block`, alignSelf: `flex-end` }}>
              <h4
                className="has-text-secondary"
                style={{ marginBottom: `1.5rem`, display: `inline-block` }}
              >
                Show work for
              </h4>
              <br />
              <Dropdown items={this.ddRegions} onChange={this.onRegionChange} />
              <Dropdown
                items={this.ddIndustries}
                onChange={this.onIndustryChange}
              />
            </aside>
          )}
        </Hero>
        <LinkGrid nodes={cases} noResultsMessage={`No case studies found.`} />
        <ClientsGrid
          showFilters={showClientFilters === true}
          clients={clients.edges.map(edge => edge.node)}
          offices={offices.edges.map(edge => {
            return {
              name: edge.node.data.name.text,
              value: edge.node.uid
            };
          })}
          industries={this.ddIndustries}
          lang={this.props.location.pathname.indexOf("/fr/") >= 0 ? "fr" : "en"}
        />
        <BigCallout {...callout} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query($uid: String!) {
    page: prismicWorkPage(uid: { eq: $uid }) {
      uid
      data {
        seo_description {
          text
        }
        seo_keywords {
          text
        }
        seo_image {
          twitter {
            url

            dimensions {
              width
              height
            }
          }
          facebook {
            url

            dimensions {
              width
              height
            }
          }
        }
        seo_image_alt {
          text
        }
        title {
          text
        }
        show_case_study_filters
        show_client_filters
        body {
          ... on PrismicWorkPageBodyHero {
            slice_type
            primary {
              show_filters
              heading {
                html
                text
              }
              paragraph {
                text
                html
              }
            }
          }
          ... on PrismicWorkPageBodyCaseStudies {
            id
            slice_type
            items {
              case_study {
                document {
                  uid
                  slug
                  tags
                  data {
                    title {
                      text
                    }
                    client {
                      document {
                        uid
                        tags
                        data {
                          name {
                            text
                          }
                        }
                      }
                    }
                    thumbnail {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWorkPageBodyCallout {
            id
            slice_type
            primary {
              theme
              button_label
              heading {
                text
              }
              link {
                document {
                  slug
                  data {
                    title {
                      text
                    }
                  }
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
    clients: allPrismicClient(
      sort: { fields: data___name___text, order: ASC }
      filter: {
        tags: { ne: null }
        data: { show_in_client_grid: { ne: "no" }, logo: { url: { ne: null } } }
      }
    ) {
      edges {
        node {
          tags
          uid
          data {
            name {
              text
            }
            offices {
              office {
                document {
                  uid
                }
              }
            }
            logo {
              url
              dimensions {
                width
                height
              }
            }
          }
        }
      }
    }
    offices: allPrismicOffice(
      filter: { uid: { regex: "/^((?!_template).)*$/" } }
    ) {
      edges {
        node {
          uid
          data {
            name {
              text
            }
          }
        }
      }
    }
  }
`;

export default WorkPage;
