import React, { PureComponent } from 'react';
import { Section, Column } from 'components/animated';
import Columns from 'react-bulma-components/lib/components/columns';
import Container from 'react-bulma-components/lib/components/container';
import posed from 'react-pose';
import Waypoint from 'react-waypoint';
import Dropdown from 'components/Dropdown';
import { renderFluidImage } from 'utils/imageHelpers';
import styles from './ClientsGrid.module.scss';
import { animatedChild, animatedContainer } from 'utils/commonPoses';

const officeOrder = [
  'new-york',
  'irvine',
  'los-angeles',
  'toronto',
  'vancouver',
  'montreal',
  'quebec-city',
  'london',
  'singapore'
];

const AnimatedSection = posed(Section)(animatedContainer);
const AnimatedColumn = posed(Column)(animatedChild);
const AnimatedItem = posed.article(animatedChild);
const AnimatedMessage = posed.h5(animatedChild);

export default class ClientsGrid extends PureComponent {
  constructor(props) {
    super(props);
    this.offices = Array.from(props.offices);
    this.offices.sort((a, b) => {
      return officeOrder.indexOf(a.value) - officeOrder.indexOf(b.value);
    });
    this.offices.unshift({ name: 'All offices', value: null });

    this.industries = Array.from(props.industries);
    this.clients = Array.from(props.clients);

    this.clients.sort((a, b) => {
      let aname = a.data.name.text.toLowerCase();
      let bname = b.data.name.text.toLowerCase();

      if (bname > aname) {
        return -1;
      }
      if (aname > bname) {
        return 1;
      }
      return 0;
    });

    this.state = {
      visible: false,
      officeFilter: null,
      industryFilter: null,
      clients: this.filteredClients(null, null)
    };
  }

  onEnter = () => {
    this.setState({ visible: true });
  };

  filteredClients(officeFilter, industryFilter) {
    if (!officeFilter && !industryFilter) {
      return this.clients;
    }

    let result = this.clients;

    if (officeFilter) {
      result = result.filter(c => {
        return (
          c.data.offices.filter(
            ({ office }) =>
              office &&
              office.document &&
              office.document[0].uid === officeFilter
          ).length > 0
        );
      });
    }

    if (industryFilter) {
      result = result.filter(c => c.tags.includes(industryFilter));
    }

    return result;
  }

  onOfficeChange = ({ value }) => {
    this.setState({
      officeFilter: value,
      clients: this.filteredClients(value, this.state.industryFilter)
    });
  };

  onIndustryChange = ({ value }) => {
    this.setState({
      industryFilter: value,
      clients: this.filteredClients(this.state.officeFilter, value)
    });
  };

  render() {
    const { clients } = this.state;
    return (
      <Waypoint
        key={'linkGrid'}
        scrollableAncestor={typeof window === 'undefined' ? null : window}
        onEnter={this.onEnter}
        topOffset={`30%`}
        bottomOffset={`30%`}
      >
        <AnimatedSection
          stagger={50}
          initialPose="hidden"
          pose={this.state.visible ? 'visible' : 'hidden'}
          className={styles.section}
        >
          <Container>
            <Columns mobile multiline>
              <AnimatedColumn mobile={{ size: 12 }} tablet={{ size: 12 }}>
                <h3 className="has-text-weight-bold">{'Our Clients'}</h3>
              </AnimatedColumn>
              {this.props.showFilters && (
                <AnimatedColumn mobile={{ size: 12 }} tablet={{ size: 12 }}>
                  <aside>
                    <Dropdown
                      theme="grey"
                      items={this.offices}
                      onChange={this.onOfficeChange}
                    />
                    <Dropdown
                      theme="grey"
                      items={this.industries}
                      onChange={this.onIndustryChange}
                    />
                  </aside>
                </AnimatedColumn>
              )}
              <Columns.Column
                mobile={{ size: 12 }}
                tablet={{ size: 12 }}
                className={styles.grid}
              >
                {clients.length > 0 &&
                  clients.map(node => {
                    const name = node.data.name;
                    const logo = node.data.logo;
                    return node ? (
                      <AnimatedItem
                        key={node.uid}
                        className={styles.client}
                        initialPose={this.state.visible ? 'visible' : 'hidden'}
                        pose={this.state.visible ? 'visible' : 'hidden'}
                      >
                        <figure className={styles.thumbnail}>
                          {renderFluidImage(logo, null, { alt: name.text })}
                        </figure>
                      </AnimatedItem>
                    ) : null;
                  })}
                {clients.length === 0 && (
                  <AnimatedMessage
                    key="no-transition"
                    initialPose={this.state.visible ? 'visible' : 'hidden'}
                    pose={this.state.visible ? 'visible' : 'hidden'}
                    className={styles.nothing}
                  >
                    No clients found.
                  </AnimatedMessage>
                )}
              </Columns.Column>
            </Columns>
          </Container>
        </AnimatedSection>
      </Waypoint>
    );
  }
}
