const getCountryCode = code => {
  let result;
  switch (code) {
    case 'us':
      result = 'us';
      break;
    case 'ca':
    default:
      result = 'canada';
      break;
  }
  return result;
};

export default getCountryCode;
